






















































































import { Component, Vue, Prop } from 'vue-property-decorator'
// @ts-ignore
import { VueMaskDirective } from 'v-mask'

Vue.directive('mask', VueMaskDirective)

@Component({})
export default class FormInput extends Vue {
  @Prop({ default: '' })
  public prepend!: string

  @Prop({ default: '' })
  public append!: string

  @Prop({ default: false })
  public noValidation!: any

  @Prop({ default: false })
  public noErrors!: any

  @Prop({ default: '' })
  public value!: string

  @Prop({ default: 'text' })
  public label_format!: string

  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: 0 })
  public debounce!: number

  @Prop({ default: false })
  public lazy!: boolean

  @Prop({ default: false })
  public vertical!: boolean

  @Prop({ default: '' })
  public name!: string

  @Prop({ default: '' })
  public mask!: any

  @Prop({ default: '' })
  public label!: string

  @Prop({ default: '' })
  public description!: string

  @Prop({ default: '' })
  public vid!: string

  @Prop({ default: '' })
  public icon!: string

  @Prop({ default: 'input' })
  public id!: string

  @Prop({ default: 'text' })
  public type!: string

  public update: boolean = true

  @Prop({ default: '' })
  public rules!: [Object, String]

  public secrect: string = ''

  public get uuid() {
    return `${this.id}-${this.secrect}`
  }

  public get formLabel() {
    return this.label ? this.label + (this.rules.includes('required') ? ' *' : '') : ''
  }

  public get local_value(): string {
    return this.value
  }

  public set local_value(val: string) {
    if (val && this.type === 'number' && typeof val === 'string') {
      val = val.replace(/[^0-9\.]/g, '')
    }

    this.$emit('input', val)
  }

  public mounted() {
    this.secrect = String(Math.floor(Math.random() * Math.floor(999)))
  }

  public created() {
    this.local_value = this.value
  }
}
